import { NgModule } from '@angular/core';
import { OktaConfig, OktaAuthModule } from '@okta/okta-angular';
import { OktaAuth, OktaAuthOptions } from '@okta/okta-auth-js';
import { environment } from 'src/environments/environment';

const onAuthRequired = async (oktaAuth: OktaAuth) => {
  // redirect to okta for login
  await oktaAuth.signInWithRedirect();
};

const { okta } = environment;

const authConfig: OktaAuthOptions = {
  issuer: okta.issuer,
  clientId: okta.clientId,
  redirectUri: window.location.origin,
  pkce: true,
  postLogoutRedirectUri: window.location.origin,
};
const oktaAuth = new OktaAuth(authConfig);
const moduleConfig: OktaConfig = { oktaAuth, onAuthRequired };

@NgModule({
  imports: [OktaAuthModule.forRoot(moduleConfig)],
})
export class AuthConfigModule {}
