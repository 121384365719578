import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { PortalAuthService } from '@bigfishgames/publisher-dashboard-core';
import { PublisherModule } from '@bigfishgames/publisher-dashboard-core';

export const checkOktaGroupGuard = (
  portalMOdule: PublisherModule,
): CanActivateFn => {
  const pathToGroupMap: Record<string, string[]> = {};
  if ('title' in portalMOdule) {
    portalMOdule.nav.forEach((navItem) => {
      pathToGroupMap[navItem.url] = navItem.groupsAllowed;
    });
  } else {
    pathToGroupMap[portalMOdule.nav.url] = portalMOdule.nav.groupsAllowed;
  }
  return async (route) => {
    const router = inject(Router);
    const portalAuthService = inject(PortalAuthService);
    const path = route.routeConfig?.path;
    const { oktaAdminGroupName } = portalMOdule;
    const allowedGroups: string[] =
      path && pathToGroupMap[path]
        ? pathToGroupMap[path]
        : route.routeConfig?.data?.['allowedGroups'] || [];
    // Add portal module admin to allowed group
    oktaAdminGroupName && allowedGroups.push(oktaAdminGroupName);
    const isAllowed = await portalAuthService.checkAccess(allowedGroups);
    if (!isAllowed) {
      router.navigateByUrl('access-denied');
    }
    return isAllowed;
  };
};
