<c-container>
    <div class="row justify-content-center">
      <div class="col-md-6">
        <c-card>
          <c-card-header class="card-header">Access Denied</c-card-header>
          <c-card-body>
            <p>You do not have permission to access this page.</p>
          </c-card-body>
        </c-card>
      </div>
    </div>
</c-container>
  