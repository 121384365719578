import { Component, Inject, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { IconSetService } from '@coreui/icons-angular';
import { iconSubset } from './icons/icon-subset';
import { Title } from '@angular/platform-browser';
import { OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent implements OnInit {
  title = 'Publisher Portal';

  constructor(
    private router: Router,
    titleService: Title,
    iconSetService: IconSetService,
    @Inject(OKTA_AUTH) public oktaAuth: OktaAuth,
  ) {
    titleService.setTitle(this.title);
    // iconSet singleton
    iconSetService.icons = { ...iconSubset };
  }

  async ngOnInit(): Promise<void> {
    await this.handleLoginRedirect();
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
    });
  }

  async handleLoginRedirect() {
    if (this.oktaAuth.isLoginRedirect()) {
      // For client side routing we need to handle redirect before any auth check in application
      await this.oktaAuth.handleRedirect();
    }
  }
}
