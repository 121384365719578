import { BehaviorSubject, Observable, firstValueFrom, map } from 'rxjs';
import { PortalAuthService } from './portal-auth.service';
import { PortalOktaGroups } from './../portal-okta-groups';

export class MockPortalAuthService extends PortalAuthService {
  private groups: BehaviorSubject<string[]>;
  private token: string | undefined;
  private isAccessAllowed = false;

  constructor(groups?: string[]) {
    super();
    this.groups = new BehaviorSubject<string[]>(groups || []);
  }

  override getGroups(): Observable<string[]> {
    return this.groups.asObservable();
  }
  override async getAccessToken(): Promise<string | undefined> {
    return this.token;
  }

  setGroups(groups: string[]): void {
    this.groups.next(groups);
  }

  setAccessToken(token: string | undefined) {
    this.token = token;
  }

  override async checkAccess(allowedGroups: string[]): Promise<boolean> {
    allowedGroups = [...allowedGroups, PortalOktaGroups.ADMIN];
    return firstValueFrom(
      this.getGroups().pipe(
        map((oktaGroups) => {
          return allowedGroups.some((group) => oktaGroups.includes(group));
        }),
      ),
    );
  }
}
