import { InjectionToken } from '@angular/core';

export enum PortalEnvironment {
  PROD = 'prod',
  STAGE = 'stage',
  SANDBOX = 'sandbox',
}

export const PORTAL_ENVIRONMENT = new InjectionToken<PortalEnvironment>(
  'Portal Environment Token',
);
