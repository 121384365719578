import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from './../environments/environment';
import { NgScrollbarModule } from 'ngx-scrollbar';
import {
  PORTAL_ENVIRONMENT,
  PortalAuthService,
} from '@bigfishgames/publisher-dashboard-core';
// Import routing module
import { AppRoutingModule } from './app-routing.module';
import { AuthConfigModule } from './auth-config.module';
// Import app component
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
// Import containers
import {
  DefaultAsideComponent,
  DefaultFooterComponent,
  DefaultHeaderComponent,
  DefaultLayoutComponent,
} from './containers';

import {
  BadgeModule,
  BreadcrumbModule,
  ButtonModule,
  FooterModule,
  GridModule,
  HeaderModule,
  NavModule,
  SidebarModule,
  DropdownModule,
  CardModule,
} from '@coreui/angular-pro';

import { IconModule, IconSetService } from '@coreui/icons-angular';
import { PORTAL_MODULES, PORTAL_MODULES_TOKEN } from './portal-modules';
import { AccessDeniedComponent } from './components';
import { PortalOktaAuthService } from './services';

const APP_CONTAINERS = [
  DefaultAsideComponent,
  DefaultFooterComponent,
  DefaultHeaderComponent,
  DefaultLayoutComponent,
];

@NgModule({
  declarations: [AppComponent, ...APP_CONTAINERS, AccessDeniedComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    BreadcrumbModule,
    FooterModule,
    GridModule,
    HeaderModule,
    SidebarModule,
    IconModule,
    NavModule,
    ButtonModule,
    SidebarModule,
    BadgeModule,
    CardModule,
    DropdownModule,
    NgScrollbarModule,
    AuthConfigModule,
    HttpClientModule,
  ],
  providers: [
    IconSetService,
    Title,
    {
      provide: PORTAL_ENVIRONMENT,
      useValue: environment.environment,
    },
    {
      provide: PORTAL_MODULES_TOKEN,
      useValue: PORTAL_MODULES,
    },
    {
      provide: PortalAuthService,
      useClass: PortalOktaAuthService,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
