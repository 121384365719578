/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit } from '@angular/core';
import { INavData } from '@coreui/angular-pro';
import { navItems } from './_nav';
import { ModuleNavigationService } from './../../services';
import { Router, ActivationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss'],
})
export class DefaultLayoutComponent implements OnInit {
  navItems: INavData[] = [...navItems];
  moduleTitle: string | undefined;

  constructor(
    private moduleNavigationService: ModuleNavigationService,
    private titleService: Title,
    private router: Router
  ) {}

  async ngOnInit(): Promise<void> {
    this.moduleTitle = 'Dashboard';

    this.moduleNavigationService
      .getSecuredNavItems()
      .subscribe((securedNavItems) => {
        this.navItems = [...navItems, ...securedNavItems];
      });

    this.router.events
      .pipe(filter((event) => event instanceof ActivationEnd))
      .subscribe((event) => {
        if (!(event as any).snapshot.firstChild) {
          this.moduleTitle = (event as any).snapshot.data.title;
          this.titleService.setTitle('Publisher Portal - ' + this.moduleTitle);
        }
      });
  }
}
