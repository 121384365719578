import { Inject, Injectable } from '@angular/core';
import { OKTA_AUTH, OktaAuthStateService } from '@okta/okta-angular';
import OktaAuth from '@okta/okta-auth-js';
import { BehaviorSubject, Observable, firstValueFrom, map } from 'rxjs';
import {
  PortalAuthService,
  PortalOktaGroups,
} from '@bigfishgames/publisher-dashboard-core';

@Injectable({
  providedIn: 'root',
})
export class PortalOktaAuthService extends PortalAuthService {
  private groups = new BehaviorSubject<string[]>([]);

  constructor(
    private oktaAuthStateService: OktaAuthStateService,

    @Inject(OKTA_AUTH) public oktaAuth: OktaAuth,
  ) {
    super();
    this.init();
  }

  override getGroups(): Observable<string[]> {
    return this.groups.asObservable();
  }

  override async getAccessToken(): Promise<string | undefined> {
    return this.oktaAuth.getAccessToken();
  }

  override async checkAccess(allowedGroups: string[]): Promise<boolean> {
    allowedGroups = [...allowedGroups, PortalOktaGroups.ADMIN];
    return firstValueFrom(
      this.getGroups().pipe(
        map((oktaGroups) => {
          return allowedGroups.some((group) => oktaGroups.includes(group));
        }),
      ),
    );
  }

  private init() {
    this.oktaAuthStateService.authState$.subscribe((authState) => {
      const groups: string[] =
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (authState.accessToken?.claims as any)?.groups || [];
      this.groups.next(groups);
    });
  }
}
