import { PublisherModule } from '@bigfishgames/publisher-dashboard-core';
import { NewRelicModuleDetail } from '@bigfishgames/nr-event-log-viewer';
import { InjectionToken } from '@angular/core';
import { checkOktaGroupGuard } from './gaurds/check-okta-group.guard';
import { Route } from '@angular/router';
import { PublisherModuleDetail as ResignPublisherModuleDetail } from '@bigfishgames/resign-pipeline';
import { PublisherModuleDetail as AccessManagerPublisherModuleDetail } from '@bigfishgames/access-manager';
import { PublisherModuleDetail as GameOnboardingPublisherModuleDetail } from '@bigfishgames/game-on-boarding';
import {PublisherModuleDetail as AnchorChatPublisherModuleDetail} from '@bigfishgames/anchor-chat';
import {PublisherModuleDetail as AppleDeviceRegistrationPublisherModuleDetail} from '@bigfishgames/apple-device-registration';

export interface IPublisherModule {
  module: PublisherModule;
  icon: string;
  modulePath: string;
}

export const PORTAL_MODULES_TOKEN = new InjectionToken<IPublisherModule[]>(
  'Portal Modules Token',
);

export const buildModuleRoutes = (): Route[] =>
  PORTAL_MODULES.map((portalModule) => ({
    path: portalModule.modulePath,
    icon: portalModule.icon,
    loadChildren: portalModule.module.loadModule,
    canActivateChild: [checkOktaGroupGuard(portalModule.module)],
  }));

/**
 * This is list of portal modules, and it will be used to build the left nav items as well as in creating routes.
 */
export const PORTAL_MODULES: IPublisherModule[] = [
  {
    module: AccessManagerPublisherModuleDetail,
    icon: 'cil-screen-desktop',
    modulePath: 'access-manager'
  },
  {
    module: NewRelicModuleDetail,
    icon: 'cil-inbox',
    modulePath: 'nr-event-log-viewer',
  },
  {
    module: ResignPublisherModuleDetail,
    icon: 'cil-speedometer',
    modulePath: 'publishing',
  },
  {
    module: GameOnboardingPublisherModuleDetail,
    icon: 'cil-gamepad',
    modulePath: 'game-onboarding'
  },
  {
    module: AnchorChatPublisherModuleDetail,
    icon: 'cil-chat-bubble',
    modulePath: 'anchor-chat'
  },
  {
    module: AppleDeviceRegistrationPublisherModuleDetail,
    icon: 'cil-speedometer',
    modulePath: 'device-registration'
  },
];