import { Inject, Injectable } from '@angular/core';
import { PortalAuthService } from '@bigfishgames/publisher-dashboard-core';
import { BehaviorSubject, Observable } from 'rxjs';
import { INavData } from '@coreui/angular-pro';
import { IPublisherModule, PORTAL_MODULES_TOKEN } from '../portal-modules';

export interface IToolNavItem extends INavData {
  allowedGroups: string[];
  children?: IToolNavItem[];
}

@Injectable({
  providedIn: 'root',
})
export class ModuleNavigationService {
  private navItems = new BehaviorSubject<IToolNavItem[]>([]);

  constructor(
    private portalAuthService: PortalAuthService,
    @Inject(PORTAL_MODULES_TOKEN) private portalModules: IPublisherModule[],
  ) {
    this.processGroups();
  }

  getSecuredNavItems(): Observable<IToolNavItem[]> {
    return this.navItems.asObservable();
  }

  private async processGroups() {
    const navigationsItems = this.buildNavigations();
    const securedNavigationItems =
      await this.filterNavigationsItems(navigationsItems);
    this.navItems.next(securedNavigationItems);
  }

  private buildNavigations(): IToolNavItem[] {
    return this.portalModules.map((portalModule) => {
      const { module, icon, modulePath } = portalModule;
      const { oktaAdminGroupName } = module;
      const defaultAllowedGroups: string[] = [];
      oktaAdminGroupName && defaultAllowedGroups.push(oktaAdminGroupName);
      if ('title' in module) {
        const { nav } = module;
        return {
          name: $localize`${module.title}`,
          allowedGroups: [], // Module will be visible if user has access to any of one sub nav.
          iconComponent: { name: icon },
          children: nav.map<IToolNavItem>((navItem) => ({
            name: navItem.name,
            iconComponent: { name: navItem.icon },
            allowedGroups: [...navItem.groupsAllowed, ...defaultAllowedGroups],
            url: `/${modulePath}/${navItem.url}`,
          })),
        };
      } else {
        const { nav } = module;
        return {
          name: $localize`${nav.name}`,
          url: `/${modulePath}/${nav.url}`,
          iconComponent: { name: icon },
          allowedGroups: [...nav.groupsAllowed, ...defaultAllowedGroups],
        };
      }
    });
  }

  private async filterNavigationsItems(navigationsItems: IToolNavItem[]) {
    const securedNavigationItems: IToolNavItem[] = [];
    for (const navigationsItem of navigationsItems) {
      if (navigationsItem.children) {
        const allowedChilNavItems: IToolNavItem[] = [];
        for (const chidNavigationsItem of navigationsItem.children) {
          if (
            await this.portalAuthService.checkAccess(
              chidNavigationsItem.allowedGroups,
            )
          ) {
            allowedChilNavItems.push(chidNavigationsItem);
          }
        }
        if (allowedChilNavItems?.length > 0) {
          securedNavigationItems.push({
            ...navigationsItem,
            children: allowedChilNavItems,
          });
        }
      } else if (
        await this.portalAuthService.checkAccess(navigationsItem.allowedGroups)
      ) {
        securedNavigationItems.push(navigationsItem);
      }
    }
    return securedNavigationItems;
  }
}
